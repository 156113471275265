import { EmployeeViewModel, SelectModelOfString } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum EmployeeState {
  employee = 'employee',
  employees = 'employees',
  saleEmployees = 'saleEmployees'
}

export interface EmployeeStateTypes {
  [EmployeeState.employee]: EmployeeViewModel | null;
  [EmployeeState.employees]: EmployeeViewModel[] | null;
  [EmployeeState.saleEmployees]: SelectModelOfString[] | null;
}

export const enum EmployeeMutation {
  setEmployee = 'setEmployee',
  setEmployees = 'setEmployees',
  setSaleEmployees = 'setSaleEmployees'
}

export interface EmployeeMutationPayloadTypes {
  [EmployeeMutation.setEmployee]: EmployeeViewModel | null;
  [EmployeeMutation.setEmployees]: EmployeeViewModel[] | null;
  [EmployeeMutation.setSaleEmployees]: SelectModelOfString[] | null;
}

export const enum EmployeeAction {
  loadEmployee = 'loadEmployee',
  clearEmployee = 'clearEmployee',
  fetchEmployees = 'fetchEmployees',
  fetchSaleEmployees = 'fetchSaleEmployees'
}

export interface EmployeeActions {
  [EmployeeAction.loadEmployee]: (id: string) => void;
  [EmployeeAction.clearEmployee]: () => void;
  [EmployeeAction.fetchEmployees]: () => Promise<void>;
  [EmployeeAction.fetchSaleEmployees]: () => Promise<void>;
}

export const enum EmployeeGetter {
  employee = 'employee'
}

export interface EmployeeGetterTypes {
  [EmployeeGetter.employee]: EmployeeViewModel | null;
}

export const EMPLOYEE_STATE_NAMESPACE = 'EmployeeState';
export const EmployeeNamespace = namespace(EMPLOYEE_STATE_NAMESPACE);

export type EmployeeModule = Override<
  Module<EmployeeStateTypes, WebRootState>,
  TypedModule<
    EmployeeStateTypes,
    EmployeeMutationPayloadTypes,
    EmployeeActions,
    EmployeeGetterTypes,
    WebRootState
  >
>;
