import ApiClientFactory from '@/api/apiClientFactory';

import {
  OtherServiceAction,
  OtherServiceGetter,
  OtherServiceModule,
  OtherServiceMutation,
  OtherServiceState
} from './other-service.module-types';

export const otherServiceModule: OtherServiceModule = {
  namespaced: true,
  state: {
    [OtherServiceState.otherService]: null
  },
  mutations: {
    [OtherServiceMutation.setOtherService]: (state, payload) => {
      state[OtherServiceState.otherService] = payload;
    }
  },
  actions: {
    [OtherServiceAction.loadOtherService]: async ({ commit }, id: number) => {
      commit(
        OtherServiceMutation.setOtherService,
        await new ApiClientFactory().otherServiceClient().get(id)
      );
    },
    [OtherServiceAction.clearOtherService]: ({ commit }) => {
      commit(OtherServiceMutation.setOtherService, null);
    }
  },
  getters: {
    [OtherServiceGetter.otherService]: (state) =>
      state[OtherServiceState.otherService]
  }
};
