import axios, { AxiosInstance } from 'axios';
import {
  ContentPageClient,
  ProductCategoryClient,
  ProductClient,
  SettingClient,
  ShapeClient,
  BlogClient,
  RevenueStatisticsClient,
  SampleClient,
  SliderClient,
  PriceClient,
  OrderClient,
  AuthClient,
  OtherServiceClient,
  CustomerClient,
  EmployeeClient,
  SampleTypeClient,
  SuppliesClient,
  SuppliesCategoryClient,
  ActivityLogClient,
  VendorClient,
  ExpensesClient
} from './api';
import store from '../store/index';
import AuthStorageService from '@/utility/account/auth.storage.service';

const API_URL = `${process.env.VUE_APP_API_BASE_HOST}`;

export default class ApiClientFactory {
  private axiosClient: AxiosInstance;

  constructor() {
    this.axiosClient = axios.create();
    this.axiosClient.interceptors.request.use((config) => {
      //const token = store.getters['AuthState/auth'];
      const token = AuthStorageService.getAuthInfo();
      if (!token) {
        return config;
      }

      // let token = tokens.access;

      // if (config.url === api.refresh) {
      //   token = tokens.refresh;
      // }

      config.headers.Authorization = `Bearer ${token.accessToken}`;

      return config;
    });
  }

  productClient = () => new ProductClient(API_URL, this.axiosClient);
  settingClient = () => new SettingClient(API_URL, this.axiosClient);
  shapeClient = () => new ShapeClient(API_URL, this.axiosClient);
  productCategoryClient = () =>
    new ProductCategoryClient(API_URL, this.axiosClient);
  contentPageClient = () => new ContentPageClient(API_URL, this.axiosClient);
  priceClient = () => new PriceClient(API_URL, this.axiosClient);
  blogClient = () => new BlogClient(API_URL, this.axiosClient);
  revenueStatisticsClient = () =>
    new RevenueStatisticsClient(API_URL, this.axiosClient);
  sampleClient = () => new SampleClient(API_URL, this.axiosClient);
  sliderClient = () => new SliderClient(API_URL, this.axiosClient);
  orderClient = () => new OrderClient(API_URL, this.axiosClient);
  authClient = () => new AuthClient(API_URL, this.axiosClient);
  otherServiceClient = () => new OtherServiceClient(API_URL, this.axiosClient);
  customerClient = () => new CustomerClient(API_URL, this.axiosClient);
  employeeClient = () => new EmployeeClient(API_URL, this.axiosClient);
  sampleTypeClient = () => new SampleTypeClient(API_URL, this.axiosClient);
  suppliesClient = () => new SuppliesClient(API_URL, this.axiosClient);
  suppliesCategoryClient = () =>
    new SuppliesCategoryClient(API_URL, this.axiosClient);
  activityLogClient = () => new ActivityLogClient(API_URL, this.axiosClient);
  vendorClient = () => new VendorClient(API_URL, this.axiosClient);
  expensesClient = () => new ExpensesClient(API_URL, this.axiosClient);
}
