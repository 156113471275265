import ApiClientFactory from '@/api/apiClientFactory';
import {
  CustomerAction,
  CustomerGetter,
  CustomerModule,
  CustomerMutation,
  CustomerState
} from './customer.module-types';

export const customerModule: CustomerModule = {
  namespaced: true,
  state: {
    [CustomerState.customer]: null,
    [CustomerState.customers]: []
  },
  mutations: {
    [CustomerMutation.setCustomer]: (state, payload) => {
      state[CustomerState.customer] = payload;
    },
    [CustomerMutation.setCustomers]: (state, payload) => {
      state[CustomerState.customers] = payload;
    }
  },
  actions: {
    [CustomerAction.clearCustomer]: ({ commit }) => {
      commit(CustomerMutation.setCustomer, null);
    },
    [CustomerAction.fetchCustomers]: async ({ commit }) => {
      const clientApi = new ApiClientFactory();
      const result = await clientApi.customerClient().getList();

      commit(CustomerMutation.setCustomers, result);
    }
  },
  getters: {
    [CustomerGetter.customer]: (state) => state[CustomerState.customer],
    [CustomerGetter.customers]: (state) => state[CustomerState.customers]
  }
};
