import ApiClientFactory from '@/api/apiClientFactory';

import {
  ProductAction,
  ProductGetter,
  ProductModule,
  ProductMutation,
  ProductState
} from './product.module-types';

export const productModule: ProductModule = {
  namespaced: true,
  state: {
    [ProductState.product]: null,
    [ProductState.products]: []
  },
  mutations: {
    [ProductMutation.setProduct]: (state, payload) => {
      state[ProductState.product] = payload;
    },
    [ProductMutation.setProducts]: (state, payload) => {
      state[ProductState.products] = payload;
    }
  },
  actions: {
    [ProductAction.loadProduct]: async ({ commit }, id: number) => {
      commit(
        ProductMutation.setProduct,
        await new ApiClientFactory().productClient().get(id)
      );
    },
    [ProductAction.clearProduct]: ({ commit }) => {
      commit(ProductMutation.setProduct, null);
    },
    [ProductAction.fetchProducts]: async ({ commit }) => {
      const products = await new ApiClientFactory()
        .productClient()
        .getProductNames();
      commit(ProductMutation.setProducts, products);
    }
  },
  getters: {
    [ProductGetter.product]: (state) => state[ProductState.product]
  }
};
