import ApiClientFactory from '@/api/apiClientFactory';

import {
  PriceAction,
  PriceGetter,
  PriceModule,
  PriceMutation,
  PriceState
} from './price.module-types';

export const priceModule: PriceModule = {
  namespaced: true,
  state: {
    [PriceState.priceList]: null
  },
  mutations: {
    [PriceMutation.setPriceList]: (state, payload) => {
      state[PriceState.priceList] = payload;
    }
  },
  actions: {
    [PriceAction.loadPriceList]: async ({ commit }) => {
      commit(
        PriceMutation.setPriceList,
        await new ApiClientFactory().priceClient().getList()
      );
    }
  },
  getters: {
    [PriceGetter.priceList]: (state) => state[PriceState.priceList]
  }
};
