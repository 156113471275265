import ApiClientFactory from '@/api/apiClientFactory';
import Vue from 'vue';

import {
  OrderAction,
  OrderGetter,
  OrderModule,
  OrderMutation,
  OrderState
} from './order.module-types';

export const orderModule: OrderModule = {
  namespaced: true,
  state: {
    [OrderState.order]: null,
    [OrderState.orderMaterial]: null,
    [OrderState.companyInvoiceInfo]: {
      companyName: 'CÔNG TY TNHH QC IN ẤN 3 DƯ',
      companyAddress:
        '24/18 Khu phố 2, Đường Nguyễn Thị Thập, P. Tân Phú, Quận 7, TP. Hồ Chí Minh',
      companyPhone: 'Điện Thoại: 0925 328 328 - Email: innhanh3du@gmail.com'
    }
  },
  mutations: {
    [OrderMutation.setOrder]: (state, payload) => {
      state[OrderState.order] = payload;
    },
    [OrderMutation.setOrderMaterial]: (state, payload) => {
      state[OrderState.orderMaterial] = payload;
    },
    [OrderMutation.setCompanyInvoiceInfo]: (state, payload) => {
      state[OrderState.companyInvoiceInfo] = {
        companyAddress: payload?.companyAddress ?? '',
        companyPhone: payload?.companyPhone ?? '',
        companyName: payload?.companyName ?? ''
      };
    },
    [OrderMutation.resetCompanyInvoiceInfo]: (state) => {
      state[OrderState.companyInvoiceInfo] = {
        companyName: 'CÔNG TY TNHH QC IN ẤN 3 DƯ',
        companyAddress:
          '24/18 Khu phố 2, Đường Nguyễn Thị Thập, P. Tân Phú, Quận 7, TP. Hồ Chí Minh',
        companyPhone: 'Điện Thoại: 0925 328 328 - Email: innhanh3du@gmail.com'
      };
    }
  },
  actions: {
    [OrderAction.loadOrder]: async ({ commit }, id: number) => {
      commit(
        OrderMutation.setOrder,
        await new ApiClientFactory().orderClient().get(id)
      );
    },
    [OrderAction.clearOrder]: ({ commit }) => {
      commit(OrderMutation.setOrder, null);
    },
    [OrderAction.getOrderMaterial]: async ({ commit }, productId) => {
      const clientApi = new ApiClientFactory();
      const result = await clientApi
        .orderClient()
        .getOrderMaterialByProductId(productId);

      commit(OrderMutation.setOrderMaterial, result);
    }
  },
  getters: {
    [OrderGetter.order]: (state) => state[OrderState.order],
    [OrderGetter.companyInvoiceInfo]: (state) =>
      state[OrderState.companyInvoiceInfo]
  }
};
