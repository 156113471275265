import {
  AuthAction,
  AuthGetter,
  AuthModule,
  AuthMutation,
  AuthState
} from './auth.module-types';

export const authModule: AuthModule = {
  namespaced: true,
  state: {
    [AuthState.Auth]: null
  },
  mutations: {
    [AuthMutation.setAuth]: (state, payload) => {
      state[AuthState.Auth] = payload;
    }
  },
  actions: {
    [AuthAction.clearAuth]: ({ commit }) => {
      commit(AuthMutation.setAuth, null);
    }
  },
  getters: {
    [AuthGetter.Auth]: (state) => state[AuthState.Auth]
  }
};
