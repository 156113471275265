import {
  BreadcrumbMutation,
  BreadcrumbAction,
  BreadcrumbModule
} from './breadcrumb.module-types';

export const breadcrumbModule: BreadcrumbModule = {
  namespaced: true,
  state: {
    items: null
  },
  mutations: {
    setItems: (state, items) => {
      state.items = items;
    }
  },
  actions: {
    [BreadcrumbAction.setItems]: ({ commit }, payload) => {
      commit(BreadcrumbMutation.setItems, payload);
    },
    [BreadcrumbAction.clearItems]: ({ commit }) => {
      commit(BreadcrumbMutation.setItems, null);
    }
  },
  getters: {
    items: (state) => state.items,
    anyBreadcrumbs: (state) => state.items != null && state.items.length > 0
  }
};
