import { BaseValueModelOfInteger, SuppliesViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum SuppliesState {
  supplies = 'supplies',
  suppliesList = 'suppliesList',
  suppliesNames = 'suppliesNames'
}

export interface SuppliesStateTypes {
  [SuppliesState.supplies]: SuppliesViewModel | null;
  [SuppliesState.suppliesList]: SuppliesViewModel[] | null;
  [SuppliesState.suppliesNames]: BaseValueModelOfInteger[] | null;
}

export const enum SuppliesMutation {
  setSupplies = 'setSupplies',
  setSuppliesList = 'setSuppliesList',
  setSuppliesNames = 'setSuppliesNames'
}

export interface SuppliesMutationPayloadTypes {
  [SuppliesMutation.setSupplies]: SuppliesViewModel | null;
  [SuppliesMutation.setSuppliesList]: SuppliesViewModel[] | null;
  [SuppliesMutation.setSuppliesNames]: BaseValueModelOfInteger[] | null;
}

export const enum SuppliesAction {
  loadSupplies = 'loadSupplies',
  clearSupplies = 'clearSupplies',
  fetchSuppliesNames = 'fetchSuppliesNames',
  fetchSuppliesList = 'fetchSuppliesList'
}

export interface SuppliesActions {
  [SuppliesAction.loadSupplies]: (id: number) => void;
  [SuppliesAction.clearSupplies]: () => void;
  [SuppliesAction.fetchSuppliesNames]: () => void;
  [SuppliesAction.fetchSuppliesList]: () => void;
}

export const enum SuppliesGetter {
  supplies = 'supplies',
  suppliesNames = 'suppliesNames'
}

export interface SuppliesGetterTypes {
  [SuppliesGetter.supplies]: SuppliesViewModel | null;
  [SuppliesGetter.suppliesNames]: BaseValueModelOfInteger[] | null;
}

export const SUPPLIES_STATE_NAMESPACE = 'SuppliesState';
export const SuppliesNamespace = namespace(SUPPLIES_STATE_NAMESPACE);

export type SuppliesModule = Override<
  Module<SuppliesStateTypes, WebRootState>,
  TypedModule<
    SuppliesStateTypes,
    SuppliesMutationPayloadTypes,
    SuppliesActions,
    SuppliesGetterTypes,
    WebRootState
  >
>;
