import ApiClientFactory from '@/api/apiClientFactory';
import {
  SuppliesCategoryAction,
  SuppliesCategoryGetter,
  SuppliesCategoryModule,
  SuppliesCategoryMutation,
  SuppliesCategoryState
} from './suppliesCategory.module-types';

export const suppliesCategoryModule: SuppliesCategoryModule = {
  namespaced: true,
  state: {
    [SuppliesCategoryState.suppliesCategory]: null,
    [SuppliesCategoryState.suppliesCategories]: []
  },
  mutations: {
    [SuppliesCategoryMutation.setSuppliesCategory]: (state, payload) => {
      state[SuppliesCategoryState.suppliesCategory] = payload;
    },
    [SuppliesCategoryMutation.setSuppliesCategories]: (state, payload) => {
      state[SuppliesCategoryState.suppliesCategories] = payload;
    }
  },
  actions: {
    [SuppliesCategoryAction.loadSuppliesCategory]: async (
      { commit },
      id: number
    ) => {
      commit(
        SuppliesCategoryMutation.setSuppliesCategory,
        await new ApiClientFactory().suppliesCategoryClient().get(id)
      );
    },
    [SuppliesCategoryAction.clearSuppliesCategory]: ({ commit }) => {
      commit(SuppliesCategoryMutation.setSuppliesCategory, null);
    },
    [SuppliesCategoryAction.fetchSuppliesCategories]: async ({ commit }) => {
      let suppliesCategories = await new ApiClientFactory()
        .suppliesCategoryClient()
        .getList();
      suppliesCategories = suppliesCategories.sort(
        (a, b) => a.orderNumber - b.orderNumber
      );
      commit(
        SuppliesCategoryMutation.setSuppliesCategories,
        suppliesCategories
      );
    }
  },
  getters: {
    [SuppliesCategoryGetter.suppliesCategory]: (state) =>
      state[SuppliesCategoryState.suppliesCategory],
    [SuppliesCategoryGetter.suppliesCategories]: (state) =>
      state[SuppliesCategoryState.suppliesCategories]
  }
};
