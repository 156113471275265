export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render(h) {
    // const span = h('span', {}, t(this.item.header));
    // const icon = h('feather-icon', {
    //   props: { icon: 'MoreHorizontalIcon', size: '18' }
    // });
    // if (canViewVerticalNavMenuHeader(this.item)) {
    //   return h('li', { class: 'navigation-header text-truncate' }, [
    //     span,
    //     icon
    //   ]);
    // }
    return h();
  }
};
