import { CustomerViewModel, VendorModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum VendorState {
  vendors = 'vendors'
}

export interface VendorStateTypes {
  [VendorState.vendors]: VendorModel[] | null;
}

export const enum VendorMutation {
  setVendors = 'setVendors'
}

export interface VendorMutationPayloadTypes {
  [VendorMutation.setVendors]: VendorModel[] | null;
}

export const enum VendorAction {
  //loadCustomer = 'loadCustomer',
  fetchVendors = 'fetchVendors'
}

export interface VendorActions {
  //[CustomerAction.loadCustomer]: (id: string) => void;
  [VendorAction.fetchVendors]: () => Promise<void>;
}

export const enum VendorGetter {
  vendors = 'vendors'
}

export interface VendorGetterTypes {
  [VendorGetter.vendors]: VendorModel[] | null;
}

export const VENDOR_STATE_NAMESPACE = 'VendorState';
export const VendorNamespace = namespace(VENDOR_STATE_NAMESPACE);

export type VendorModule = Override<
  Module<VendorStateTypes, WebRootState>,
  TypedModule<
    VendorStateTypes,
    VendorMutationPayloadTypes,
    VendorActions,
    VendorGetterTypes,
    WebRootState
  >
>;
