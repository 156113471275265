import Vue from 'vue';
import VueRouter from 'vue-router';
import { dispatchBreadcrumbAction } from '@/store/breadcrumb/breadcrumb.dispatch';
import { BreadcrumbAction } from '@/store/breadcrumb/breadcrumb.module-types';
import AuthStorageService from '@/utility/account/auth.storage.service';
import { USER_PERMISSION } from '@/utility/constants';

Vue.use(VueRouter);

export const LOGIN_ROUTE_NAME = 'Login';
export const REVENUE_STATISTICS_ROUTE_NAME = 'RevenueStatistics';
export const PRODUCT_LIST_ROUTE_NAME = 'ProductList';
export const PRODUCT_ROUTE_NAME = 'Product';
export const PRICE_LIST_ROUTE_NAME = 'PriceList';
export const OTHER_SERVICE_LIST_ROUTE_NAME = 'OtherServiceList';
export const SET_OTHER_SERVICE_PRICE_ROUTE_NAME = 'SetOtherServicePrice';
export const ORDDER_FORM_ROUTE_NAME = 'OrderManagement';
export const CUSTOMER_LIST_ROUTE_NAME = 'CustomerList';
export const EMPLOYEE_ROUTE_NAME = 'Employee';
export const PRODUCING_ROUTE_NAME = 'ProducingManagement';
export const SAMPLE_TYPE_LIST_ROUTE_NAME = 'sample-type-list';
export const PRODUCT_CATEGORY_LIST_ROUTE_NAME = 'product-category-list';
export const SETTING_ROUTE_NAME = 'setting';
export const CONTENT_PAGE_ROUTE_NAME = 'content-page';
export const BLOG_ROUTE_NAME = 'blog';
export const SAMPLE_LIST_ROUTE_NAME = 'sample-list';
export const SLIDER_ROUTE_NAME = 'slider';
export const PRINT_ROUTE_NAME = 'print';
export const COUPON_ROUTE_NAME = 'coupon';
export const PRODUCT_MENU_SETTING_ROUTE_NAME = 'product-menu-setting';
export const SUPPLIES_LIST_ROUTE_NAME = 'suppliesList';
export const SUPPLIES_CATEGORY_LIST_ROUTE_NAME = 'supplies-category-list';
export const EXPENSES_ROUTE_NAME = 'expenses';

export const DEFAULT_ROUTE = REVENUE_STATISTICS_ROUTE_NAME;

const PermissionPages = [
  {
    Permission: USER_PERMISSION.PERMISSION_ADMIN,
    AllowPages: ['*']
  },
  {
    Permission: USER_PERMISSION.PERMISSION_ORDER,
    AllowPages: [
      REVENUE_STATISTICS_ROUTE_NAME,
      CUSTOMER_LIST_ROUTE_NAME,
      ORDDER_FORM_ROUTE_NAME,
      PRODUCING_ROUTE_NAME,
      PRINT_ROUTE_NAME
    ]
  },
  {
    Permission: USER_PERMISSION.PERMISSION_SALE,
    AllowPages: [
      REVENUE_STATISTICS_ROUTE_NAME,
      CUSTOMER_LIST_ROUTE_NAME,
      ORDDER_FORM_ROUTE_NAME,
      PRODUCING_ROUTE_NAME,
      PRINT_ROUTE_NAME
    ]
  },
  {
    Permission: USER_PERMISSION.PERMISSION_PRODUCING,
    AllowPages: [
      REVENUE_STATISTICS_ROUTE_NAME,
      ORDDER_FORM_ROUTE_NAME,
      PRODUCING_ROUTE_NAME
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/login',
      name: LOGIN_ROUTE_NAME,
      component: () => import('@/views/login/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true
      }
    },
    {
      path: '',
      name: REVENUE_STATISTICS_ROUTE_NAME,
      component: () =>
        import(
          '@/views/revenueStatistics/revenueStatisticsList/RevenueStatisticsList.vue'
        )
    },
    {
      path: '/sample-list/sample-types',
      name: SAMPLE_TYPE_LIST_ROUTE_NAME,
      component: () =>
        import('@/views/sampleType/sampleTypeList/SampleTypeList.vue')
    },
    {
      path: '/product-category-list',
      name: PRODUCT_CATEGORY_LIST_ROUTE_NAME,
      component: () =>
        import(
          '@/views/productCategory/productCategoryList/ProductCategoryList.vue'
        )
    },
    {
      path: '/product-list',
      name: PRODUCT_LIST_ROUTE_NAME,
      component: () => import('@/views/product/productList/ProductList.vue')
    },
    {
      path: '/product-form/:id?',
      name: PRODUCT_ROUTE_NAME,
      component: () => import('@/views/product/productForm/ProductForm.vue')
    },
    {
      path: '/setting',
      name: SETTING_ROUTE_NAME,
      component: () => import('@/views/setting/settingList/SettingList.vue')
    },
    {
      path: '/content-page-list',
      name: CONTENT_PAGE_ROUTE_NAME,
      component: () =>
        import('@/views/contentPage/contentPageList/ContentPageList.vue')
    },
    {
      path: '/content-page-form/:id?',
      name: CONTENT_PAGE_ROUTE_NAME,
      component: () =>
        import('@/views/contentPage/contentPageForm/ContentPageForm.vue')
    },
    {
      path: '/price-list',
      name: PRICE_LIST_ROUTE_NAME,
      component: () => import('@/views/price/priceList/PriceList.vue')
    },
    {
      path: '/blog-list',
      name: BLOG_ROUTE_NAME,
      component: () => import('@/views/blog/blogList/BlogList.vue')
    },
    {
      path: '/blog-form/:id?',
      name: BLOG_ROUTE_NAME,
      component: () => import('@/views/blog/blogForm/BlogForm.vue')
    },
    {
      path: '/revenue-statistics/:id?',
      name: REVENUE_STATISTICS_ROUTE_NAME,
      component: () =>
        import(
          '@/views/revenueStatistics/revenueStatisticsList/RevenueStatisticsList.vue'
        )
    },
    {
      path: '/sample-list',
      name: SAMPLE_LIST_ROUTE_NAME,
      component: () => import('@/views/sample/sampleList/SampleList.vue')
      // children: [
      //   {
      //     path: 'sample-types',
      //     component: () =>
      //       import('@/views/sampleType/sampleTypeList/SampleTypeList.vue')
      //   }
      // ]
    },
    {
      path: '/slider/',
      name: SLIDER_ROUTE_NAME,
      component: () => import('@/views/slider/sliderForm/SliderForm.vue')
    },
    {
      path: '/order',
      name: ORDDER_FORM_ROUTE_NAME,
      component: () => import('@/views/order/OrderPage.vue')
    },
    // {
    //   path: '/order-detail/:id',
    //   name: ORDDER_FORM_ROUTE_NAME,
    //   component: () => import('@/views/order/orderDetail/OrderDetail.vue')
    // },
    {
      path: '/print-order/:id',
      name: PRINT_ROUTE_NAME,
      component: () =>
        import('@/views/print/orderDetailPrint/OrderDetailPrint.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/other-service-list',
      name: OTHER_SERVICE_LIST_ROUTE_NAME,
      component: () =>
        import('@/views/otherService/otherServiceList/OtherServiceList.vue')
    },
    {
      path: '/set-other-service-price/:id?',
      name: SET_OTHER_SERVICE_PRICE_ROUTE_NAME,
      component: () =>
        import('@/views/otherService/otherServicePrice/OtherServicePrice.vue')
    },
    {
      path: '/customer-list',
      name: CUSTOMER_LIST_ROUTE_NAME,
      component: () => import('@/views/customer/customerList/CustomerList.vue')
    },
    {
      path: '/employee-list',
      name: EMPLOYEE_ROUTE_NAME,
      component: () => import('@/views/employee/employeeList/EmployeeList.vue')
    },
    {
      path: '/employee-form/:id?',
      name: EMPLOYEE_ROUTE_NAME,
      component: () => import('@/views/employee/employeeForm/EmployeeForm.vue')
    },
    {
      path: '/producing',
      name: PRODUCING_ROUTE_NAME,
      component: () => import('@/views/producing/ProducingPage.vue')
    },
    {
      path: '/product-menu-setting',
      name: PRODUCT_MENU_SETTING_ROUTE_NAME,
      component: () =>
        import(
          '@/views/productCategory/productMenuSetting/ProductMenuSettingTabs.vue'
        )
    },
    {
      path: '/supplies',
      name: SUPPLIES_LIST_ROUTE_NAME,
      component: () => import('@/views/supplies/suppliesList/SuppliesList.vue')
    },
    {
      path: '/supplies-category-list',
      name: SUPPLIES_CATEGORY_LIST_ROUTE_NAME,
      component: () =>
        import(
          '@/views/suppliesCategory/suppliesCategoryList/SuppliesCategoryList.vue'
        )
    },
    {
      path: '/expenses',
      name: EXPENSES_ROUTE_NAME,
      component: () => import('@/views/expenses/ExpensesPage.vue')
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
});

router.beforeEach((to, _, next) => {
  dispatchBreadcrumbAction(BreadcrumbAction.clearItems);

  const token = AuthStorageService.getAuthInfo();
  const allowToAccessPages = PermissionPages.find(
    (s) =>
      AuthStorageService.getAuthInfo()?.claims?.indexOf(s.Permission) !== -1
  )?.AllowPages;

  if (token) {
    if (to.meta.redirectIfLoggedIn) {
      next({ name: DEFAULT_ROUTE });
    } else if (
      allowToAccessPages?.indexOf('*') != -1 ||
      allowToAccessPages?.indexOf(to.name ?? '') != -1
    ) {
      next();
    } else {
      return;
    }
  } else {
    if (to.name === LOGIN_ROUTE_NAME || to.meta.noAuth) {
      next();
    } else {
      next({ name: LOGIN_ROUTE_NAME });
    }
  }
});

export default router;
