import { ExpensesViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum ExpensesState {
  expenses = 'expenses',
  expensesMaterial = 'expensesMaterial',
  companyInvoiceInfo = 'companyInvoiceInfo'
}

export interface ExpensesStateTypes {
  [ExpensesState.expenses]: ExpensesViewModel | null;
  [ExpensesState.companyInvoiceInfo]: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  } | null;
}

export const enum ExpensesMutation {
  setExpenses = 'setExpenses',
  setCompanyInvoiceInfo = 'setCompanyInvoiceInfo',
  resetCompanyInvoiceInfo = 'resetCompanyInvoiceInfo'
}

export interface ExpensesMutationPayloadTypes {
  [ExpensesMutation.setExpenses]: ExpensesViewModel | null;
  [ExpensesMutation.setCompanyInvoiceInfo]: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  } | null;
  [ExpensesMutation.resetCompanyInvoiceInfo]: null;
}

export const enum ExpensesAction {
  loadExpenses = 'loadExpenses',
  clearExpenses = 'clearExpenses'
}

export interface ExpensesActions {
  [ExpensesAction.loadExpenses]: (id: number) => void;
  [ExpensesAction.clearExpenses]: () => void;
}

export const enum ExpensesGetter {
  expenses = 'expenses',
  companyInvoiceInfo = 'companyInvoiceInfo'
}

export interface ExpensesGetterTypes {
  [ExpensesGetter.expenses]: ExpensesViewModel | null;
  [ExpensesGetter.companyInvoiceInfo]: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  } | null;
}

export const EXPENSES_STATE_NAMESPACE = 'ExpensesState';
export const ExpensesNamespace = namespace(EXPENSES_STATE_NAMESPACE);

export type ExpensesModule = Override<
  Module<ExpensesStateTypes, WebRootState>,
  TypedModule<
    ExpensesStateTypes,
    ExpensesMutationPayloadTypes,
    ExpensesActions,
    ExpensesGetterTypes,
    WebRootState
  >
>;
