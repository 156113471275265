import ApiClientFactory from '@/api/apiClientFactory';
import { getListModule } from '@/utility/pagination/get-list-module';
import { getListDispatcher } from '@/utility/pagination/get-list.dispatcher';
import {
  getItemsPerPage,
  getSortCriteria,
  getValueOrUndefined,
  getZeroBasedPageNumber,
  Pagination
} from '@/utility/pagination/pagination';
import { namespace } from 'vuex-class';

export const SAMPLE_TYPE_LIST_STATE_NAMESPACE = 'sampleTypeListState';

export const sampleTypeListNamespace = namespace(
  SAMPLE_TYPE_LIST_STATE_NAMESPACE
);

export interface SampleTypeListInput extends Pagination {}

const getSampleTypeList = (input: SampleTypeListInput) => {
  return new ApiClientFactory()
    .sampleTypeClient()
    .getList(
      true,
      getZeroBasedPageNumber(input),
      getItemsPerPage(input),
      getValueOrUndefined(input.searchText),
      getSortCriteria(input)
    );
};

export const sampleTypeListDispatcher = getListDispatcher<SampleTypeListInput>(
  SAMPLE_TYPE_LIST_STATE_NAMESPACE
);
export const sampleTypeListModule = getListModule(getSampleTypeList);
