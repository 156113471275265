import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';
import { Token } from '@/api/api';

export const enum AuthState {
  Auth = 'auth'
}

export interface AuthStateTypes {
  [AuthState.Auth]: Token | null;
}

export const enum AuthMutation {
  setAuth = 'setAuth'
}

export interface AuthMutationPayloadTypes {
  [AuthMutation.setAuth]: Token | null;
}

export const enum AuthAction {
  loadAuth = 'loadAuth',
  clearAuth = 'clearAuth'
}

export interface AuthActions {
  [AuthAction.clearAuth]: () => void;
}

export const enum AuthGetter {
  Auth = 'auth'
}

export interface AuthGetterTypes {
  [AuthGetter.Auth]: Token | null;
}

export const AUTH_STATE_NAMESPACE = 'AuthState';
export const AuthNamespace = namespace(AUTH_STATE_NAMESPACE);

export type AuthModule = Override<
  Module<AuthStateTypes, WebRootState>,
  TypedModule<
    AuthStateTypes,
    AuthMutationPayloadTypes,
    AuthActions,
    AuthGetterTypes,
    WebRootState
  >
>;
