import ApiClientFactory from '@/api/apiClientFactory';
import { getListModule } from '@/utility/pagination/get-list-module';
import { getListDispatcher } from '@/utility/pagination/get-list.dispatcher';
import {
  getItemsPerPage,
  getSortCriteria,
  getValueOrUndefined,
  getZeroBasedPageNumber,
  Pagination
} from '@/utility/pagination/pagination';
import { namespace } from 'vuex-class';

export const SUPPLIES_LIST_STATE_NAMESPACE = 'suppliesListState';

export const suppliesListNamespace = namespace(SUPPLIES_LIST_STATE_NAMESPACE);

export type SuppliesListInput = Pagination;

const getSuppliesList = (input: SuppliesListInput) =>
  new ApiClientFactory()
    .suppliesClient()
    .getListPaged(
      getZeroBasedPageNumber(input),
      getItemsPerPage(input),
      getValueOrUndefined(input.searchText),
      getSortCriteria(input)
    );

export const suppliesListDispatcher = getListDispatcher<SuppliesListInput>(
  SUPPLIES_LIST_STATE_NAMESPACE
);
export const suppliesListModule = getListModule(getSuppliesList);
