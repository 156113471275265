import { getDispatch } from '../get-dispatch';
import { DispatchAction } from '../utility/dispatch-action';
import {
  BreadcrumbActions,
  BREADCRUMB_STATE_NAMESPACE
} from './breadcrumb.module-types';

export const dispatchBreadcrumbAction: DispatchAction<BreadcrumbActions> = getDispatch(
  BREADCRUMB_STATE_NAMESPACE
);
