import { OrderStatus } from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { getListModule } from '@/utility/pagination/get-list-module';
import { getListDispatcher } from '@/utility/pagination/get-list.dispatcher';
import {
  getItemsPerPage,
  getSortCriteria,
  getValueOrUndefined,
  getZeroBasedPageNumber,
  Pagination
} from '@/utility/pagination/pagination';
import { namespace } from 'vuex-class';

export const EXPENSES_LIST_STATE_NAMESPACE = 'expensesListState';

export const expensesListNamespace = namespace(EXPENSES_LIST_STATE_NAMESPACE);

export interface ExpensesListInput extends Pagination {
  filterStatus: DropdownOption[];
  filterPaymentStatus: DropdownOption[];
  dateFrom?: Date;
  dateTo?: Date;
  filterEmployee?: string;
  isExportBilling?: boolean;
}

const getExpensesList = (input: ExpensesListInput) => {
  let from, to;
  if (input.dateFrom) {
    from = new Date(
      input.dateFrom.getFullYear(),
      input.dateFrom.getMonth(),
      input.dateFrom.getDate(),
      0,
      0,
      0
    );
  }

  if (input.dateTo) {
    // input.dateTo.setDate(input.dateTo.getDate() + 1);
    to = new Date(
      input.dateTo.getFullYear(),
      input.dateTo.getMonth(),
      input.dateTo.getDate(),
      0,
      0,
      0
    );
    to.setDate(to.getDate() + 1);
  }

  return new ApiClientFactory()
    .expensesClient()
    .getList(
      to ? to.getTime() : null,
      from ? from.getTime() : null,
      getValueOrUndefined(input.filterStatus.map((x) => x.value)),
      getValueOrUndefined(input.filterPaymentStatus.map((x) => x.value)),
      getValueOrUndefined(input.filterEmployee),
      getValueOrUndefined(input.isExportBilling),
      getZeroBasedPageNumber(input),
      getItemsPerPage(input),
      getValueOrUndefined(input.searchText),
      getSortCriteria(input)
    );
};

export const expensesListDispatcher = getListDispatcher<ExpensesListInput>(
  EXPENSES_LIST_STATE_NAMESPACE
);
export const expensesListModule = getListModule(getExpensesList);
