
















































































import { onUnmounted } from '@vue/composition-api';
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue';
import useAppConfig from '@/@core/app-config/useAppConfig';
import { BNavbar } from 'bootstrap-vue';
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue';
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue';
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue';
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue';
import useVerticalLayout from './useVerticalLayout';
import mixinVerticalLayout from './mixinVerticalLayout1';

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer(): string {
      // const rendererType = this.$route.meta.contentRenderer;
      // if (rendererType === 'sidebar-left')
      //   return 'layout-content-renderer-left';
      // if (rendererType === 'sidebar-left-detached')
      //   return 'layout-content-renderer-left-detached';
      return 'layout-content-renderer-default';
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const {
      routerTransition,
      navbarBackgroundColor,
      navbarType,
      footerType,
      isNavMenuHidden
    } = useAppConfig();

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass
    } = useVerticalLayout(navbarType, footerType);

    // Resize handler
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler);
    });

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden
    };
  }
};
