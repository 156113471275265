import ApiClientFactory from '@/api/apiClientFactory';
import {
  SampleAction,
  SampleGetter,
  SampleModule,
  SampleMutation,
  SampleState
} from './sample.module-types';

export const sampleModule: SampleModule = {
  namespaced: true,
  state: {
    [SampleState.sample]: null
  },
  mutations: {
    [SampleMutation.setSample]: (state, payload) => {
      state[SampleState.sample] = payload;
    }
  },
  actions: {
    [SampleAction.loadSample]: async ({ commit }, id: number) => {
      commit(
        SampleMutation.setSample,
        await new ApiClientFactory().sampleClient().get(id)
      );
    },
    [SampleAction.clearSample]: ({ commit }) => {
      commit(SampleMutation.setSample, null);
    }
  },
  getters: {
    [SampleGetter.sample]: (state) => state[SampleState.sample]
  }
};
