import { OtherServiceViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum OtherServiceState {
  otherService = 'otherService'
}

export interface OtherServiceStateTypes {
  [OtherServiceState.otherService]: OtherServiceViewModel | null;
}

export const enum OtherServiceMutation {
  setOtherService = 'setOtherService'
}

export interface OtherServiceMutationPayloadTypes {
  [OtherServiceMutation.setOtherService]: OtherServiceViewModel | null;
}

export const enum OtherServiceAction {
  loadOtherService = 'loadOtherService',
  clearOtherService = 'clearOtherService'
}

export interface OtherServiceActions {
  [OtherServiceAction.loadOtherService]: (id: number) => void;
  [OtherServiceAction.clearOtherService]: () => void;
}

export const enum OtherServiceGetter {
  otherService = 'otherService'
}

export interface OtherServiceGetterTypes {
  [OtherServiceGetter.otherService]: OtherServiceViewModel | null;
}

export const OTHER_SERVICE_STATE_NAMESPACE = 'OtherServiceState';
export const OtherServiceNamespace = namespace(OTHER_SERVICE_STATE_NAMESPACE);

export type OtherServiceModule = Override<
  Module<OtherServiceStateTypes, WebRootState>,
  TypedModule<
    OtherServiceStateTypes,
    OtherServiceMutationPayloadTypes,
    OtherServiceActions,
    OtherServiceGetterTypes,
    WebRootState
  >
>;
