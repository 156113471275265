import ApiClientFactory from '@/api/apiClientFactory';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { getListModule } from '@/utility/pagination/get-list-module';
import { getListDispatcher } from '@/utility/pagination/get-list.dispatcher';
import {
  getItemsPerPage,
  getSortCriteria,
  getValueOrUndefined,
  getZeroBasedPageNumber,
  Pagination
} from '@/utility/pagination/pagination';
import { namespace } from 'vuex-class';

export const CUSTOMER_LIST_STATE_NAMESPACE = 'customerListState';

export const customerListNamespace = namespace(CUSTOMER_LIST_STATE_NAMESPACE);

export interface CustomerListInput extends Pagination {
  filterLevel: DropdownOption[];
}

const getCustomerList = (input: CustomerListInput) => {
  return new ApiClientFactory()
    .customerClient()
    .getListPaged(
      getValueOrUndefined(input.filterLevel.map((x) => x.value)),
      getZeroBasedPageNumber(input),
      getItemsPerPage(input),
      getValueOrUndefined(input.searchText),
      getSortCriteria(input)
    );
};

export const customerListDispatcher = getListDispatcher<CustomerListInput>(
  CUSTOMER_LIST_STATE_NAMESPACE
);
export const customerListModule = getListModule(getCustomerList);
