import { CUSTOMER_STATE_NAMESPACE } from './customer/customer.module-types';
import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import { appModule } from './app/app.module';
import { APP_STATE_NAMESPACE } from './app/app.module-types';
import { appConfigModule } from './app-config/app-config.module';
import { APP_CONFIG_STATE_NAMESPACE } from './app-config/app-config.module-types';
import { verticalMenuModule } from './vertical-menu/vertical-menu.module';
import { VERTICAL_MENU_STATE_NAMESPACE } from './vertical-menu/vertical-menu.module-types';

import {
  productListModule,
  PRODUCT_LIST_STATE_NAMESPACE
} from '@/views/product/productList/store/product-list.module';
import { productModule } from './product/product.module';
import { PRODUCT_STATE_NAMESPACE } from './product/product.module-types';
import {
  settingListModule,
  SETTING_LIST_STATE_NAMESPACE
} from '@/views/setting/settingList/store/setting-list.module';
import { settingModule } from './setting/setting.module';
import { SETTING_STATE_NAMESPACE } from './setting/setting.module-types';
import { shapeModule } from './shape/shape.module';
import { SHAPE_STATE_NAMESPACE } from './shape/shape.module-types';
import {
  productCategoryListModule,
  PRODUCT_CATEGORY_LIST_STATE_NAMESPACE
} from '@/views/productCategory/productCategoryList/store/productCategory-list.module';
import { productCategoryModule } from './productCategory/productCategory.module';
import { PRODUCT_CATEGORY_STATE_NAMESPACE } from './productCategory/productCategory.module-types';
import {
  contentPageListModule,
  CONTENT_PAGE_LIST_STATE_NAMESPACE
} from '@/views/contentPage/contentPageList/store/content-page-list.module';
import { CONTENT_PAGE_STATE_NAMESPACE } from './contentPage/content-page.module-types';
import { contentPageModule } from './contentPage/content-page.module';
import {
  blogListModule,
  BLOG_LIST_STATE_NAMESPACE
} from '@/views/blog/blogList/store/blog-list.module';
import { BLOG_STATE_NAMESPACE } from './blog/blog.module-types';
import { blogModule } from './blog/blog.module';
import {
  sampleListModule,
  SAMPLE_LIST_STATE_NAMESPACE
} from '@/views/sample/sampleList/store/sample-list.module';
import { SAMPLE_STATE_NAMESPACE } from './sample/sample.module-types';
import { sampleModule } from './sample/sample.module';
import { SLIDER_STATE_NAMESPACE } from './slider/slider.module-types';
import { sliderModule } from './slider/slider.module';
import { PRICE_STATE_NAMESPACE } from './price/price.module-types';
import { priceModule } from './price/price.module';
import {
  orderListModule,
  ORDER_LIST_STATE_NAMESPACE
} from '@/views/order/orderList/store/order-list.module';
import { breadcrumbModule } from './breadcrumb/breadcrumb.module';
import { BREADCRUMB_STATE_NAMESPACE } from './breadcrumb/breadcrumb.module-types';

import { ORDER_STATE_NAMESPACE } from './order/order.module-types';
import { orderModule } from './order/order.module';
import {
  otherServiceListModule,
  OTHER_SERVICE_LIST_STATE_NAMESPACE
} from '@/views/otherService/otherServiceList/store/other-service-list.module';
import { OTHER_SERVICE_STATE_NAMESPACE } from './otherService/other-service.module-types';
import { otherServiceModule } from './otherService/other-service.module';
import {
  customerListModule,
  CUSTOMER_LIST_STATE_NAMESPACE
} from '@/views/customer/customerList/store/customer-list.module';
import {
  employeeListModule,
  EMPLOYEE_LIST_STATE_NAMESPACE
} from '@/views/employee/employeeList/store/employee-list.module';
import { EMPLOYEE_STATE_NAMESPACE } from './employee/employee.module-types';
import { employeeModule } from './employee/employee.module';
import {
  sampleTypeListModule,
  SAMPLE_TYPE_LIST_STATE_NAMESPACE
} from '@/views/sampleType/sampleTypeList/sample-type-list.modules';
import { CATALOGUE_PRICE_STATE_NAMESPACE } from './catalougePrice/catalogue-price.module-types';
import { cataloguePriceModule } from './catalougePrice/catalogue-price.module';
import { customerModule } from './customer/customer.module';
import {
  producingListModule,
  PRODUCING_LIST_STATE_NAMESPACE
} from '@/views/producing/producingList/store/producing-list.module';
import { AUTH_STATE_NAMESPACE } from './auth/auth.module-types';
import { authModule } from './auth/auth.module';
import {
  expensesListModule,
  EXPENSES_LIST_STATE_NAMESPACE
} from '@/views/expenses/expensesList/store/expenses-list.module';
import { suppliesModule } from './supplies/supplies.module';
import { suppliesCategoryModule } from './suppliesCategory/suppliesCategory.module';
import { SUPPLIES_STATE_NAMESPACE } from './supplies/supplies.module-types';
import { SUPPLIES_CATEGORY_STATE_NAMESPACE } from './suppliesCategory/suppliesCategory.module-types';
import {
  suppliesListModule,
  SUPPLIES_LIST_STATE_NAMESPACE
} from '@/views/supplies/suppliesList/store/supplies-list.module';
import {
  SUPPLIES_CATEGORY_LIST_STATE_NAMESPACE,
  suppliesCategoryListModule
} from '@/views/suppliesCategory/suppliesCategoryList/store/SuppliesCategory-list.module';
import { VENDOR_STATE_NAMESPACE } from './vendor/vendor.module-types';
import { vendorModule } from './vendor/vendor.module';
import { EXPENSES_STATE_NAMESPACE } from './expenses/expenses.module-types';
import { expensesModule } from './expenses/expenses.module';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    [PRODUCT_LIST_STATE_NAMESPACE]: productListModule,
    [PRODUCT_STATE_NAMESPACE]: productModule,
    [SETTING_LIST_STATE_NAMESPACE]: settingListModule,
    [SETTING_STATE_NAMESPACE]: settingModule,
    [SHAPE_STATE_NAMESPACE]: shapeModule,
    [PRODUCT_CATEGORY_LIST_STATE_NAMESPACE]: productCategoryListModule,
    [PRODUCT_CATEGORY_STATE_NAMESPACE]: productCategoryModule,
    [BLOG_LIST_STATE_NAMESPACE]: blogListModule,
    [BLOG_STATE_NAMESPACE]: blogModule,
    [APP_STATE_NAMESPACE]: appModule,
    [APP_CONFIG_STATE_NAMESPACE]: appConfigModule,
    [VERTICAL_MENU_STATE_NAMESPACE]: verticalMenuModule,
    [CONTENT_PAGE_STATE_NAMESPACE]: contentPageModule,
    [CONTENT_PAGE_LIST_STATE_NAMESPACE]: contentPageListModule,
    [SAMPLE_LIST_STATE_NAMESPACE]: sampleListModule,
    [SAMPLE_STATE_NAMESPACE]: sampleModule,
    [SLIDER_STATE_NAMESPACE]: sliderModule,
    [PRICE_STATE_NAMESPACE]: priceModule,
    [BREADCRUMB_STATE_NAMESPACE]: breadcrumbModule,
    [ORDER_LIST_STATE_NAMESPACE]: orderListModule,
    [ORDER_STATE_NAMESPACE]: orderModule,
    [OTHER_SERVICE_LIST_STATE_NAMESPACE]: otherServiceListModule,
    [OTHER_SERVICE_STATE_NAMESPACE]: otherServiceModule,
    [CUSTOMER_LIST_STATE_NAMESPACE]: customerListModule,
    [EMPLOYEE_LIST_STATE_NAMESPACE]: employeeListModule,
    [EMPLOYEE_STATE_NAMESPACE]: employeeModule,
    [SAMPLE_TYPE_LIST_STATE_NAMESPACE]: sampleTypeListModule,
    [CATALOGUE_PRICE_STATE_NAMESPACE]: cataloguePriceModule,
    [CUSTOMER_STATE_NAMESPACE]: customerModule,
    [PRODUCING_LIST_STATE_NAMESPACE]: producingListModule,
    [AUTH_STATE_NAMESPACE]: authModule,
    [EXPENSES_LIST_STATE_NAMESPACE]: expensesListModule,
    [EXPENSES_STATE_NAMESPACE]: expensesModule,
    [SUPPLIES_STATE_NAMESPACE]: suppliesModule,
    [SUPPLIES_LIST_STATE_NAMESPACE]: suppliesListModule,
    [SUPPLIES_CATEGORY_STATE_NAMESPACE]: suppliesCategoryModule,
    [SUPPLIES_CATEGORY_LIST_STATE_NAMESPACE]: suppliesCategoryListModule,
    [VENDOR_STATE_NAMESPACE]: vendorModule
  }
});

export default store;
