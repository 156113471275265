<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData ? userData.displayName : '' }}
        </p>
        <span class="user-status">amin</span>
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      ></b-avatar>
    </template>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ $t('Logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BAvatar, BDropdownItem } from 'bootstrap-vue';
import jwt_decode from 'jwt-decode';
import AuthStorageService from '@/utility/account/auth.storage.service';
import { LOGIN_ROUTE_NAME } from '@/router';

export default {
  components: {
    BNavItemDropdown,
    BAvatar,
    BDropdownItem
  },
  data() {
    return {
      userData: AuthStorageService.getAuthInfo()
        ? jwt_decode(AuthStorageService.getAuthInfo().accessToken)
        : null
    };
  },
  methods: {
    logout() {
      AuthStorageService.clearAuthInfo();

      this.$router.push({ name: LOGIN_ROUTE_NAME });
    }
  }
};
</script>
