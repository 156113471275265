import { TypedModule } from '@/store/utility/module-utility-types';
import { namespace } from 'vuex-class';
import { WebRootState } from '../web-root-state';
import { Module } from 'vuex';
import { Location } from 'vue-router';

export interface BreadcrumbItem {
  to?: Location;
  text: string;
  active?: boolean;
  needGlobalization?: boolean;
}

export interface BreadcrumbState {
  items: BreadcrumbItem[] | null;
}

export const enum BreadcrumbMutation {
  setItems = 'setItems'
}

interface BreadcrumbMutationPayloadTypes {
  [BreadcrumbMutation.setItems]: BreadcrumbItem[] | null;
}

export const enum BreadcrumbAction {
  setItems = 'setItems',
  clearItems = 'clearItems'
}

export interface BreadcrumbActions {
  setItems: (payload: BreadcrumbItem[]) => void;
  clearItems: () => void;
}

export const enum BreadcrumbGetter {
  items = 'items',
  anyBreadcrumbs = 'anyBreadcrumbs'
}

export interface BreadcrumbGetterTypes {
  [BreadcrumbGetter.items]: BreadcrumbItem[] | null;
  [BreadcrumbGetter.anyBreadcrumbs]: boolean;
}

export const BREADCRUMB_STATE_NAMESPACE = 'BreadcrumbState';
export const BreadcrumbNamespace = namespace(BREADCRUMB_STATE_NAMESPACE);

export type BreadcrumbModule = Override<
  Module<BreadcrumbState, WebRootState>,
  TypedModule<
    BreadcrumbState,
    BreadcrumbMutationPayloadTypes,
    BreadcrumbActions,
    BreadcrumbGetterTypes,
    WebRootState
  >
>;
