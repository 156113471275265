import ApiClientFactory from '@/api/apiClientFactory';
import {
  SuppliesAction,
  SuppliesGetter,
  SuppliesModule,
  SuppliesMutation,
  SuppliesState
} from './supplies.module-types';

export const suppliesModule: SuppliesModule = {
  namespaced: true,
  state: {
    [SuppliesState.supplies]: null,
    [SuppliesState.suppliesList]: [],
    [SuppliesState.suppliesNames]: []
  },
  mutations: {
    [SuppliesMutation.setSupplies]: (state, payload) => {
      state[SuppliesState.supplies] = payload;
    },
    [SuppliesMutation.setSuppliesList]: (state, payload) => {
      state[SuppliesState.suppliesList] = payload;
    },
    [SuppliesMutation.setSuppliesNames]: (state, payload) => {
      state[SuppliesState.suppliesNames] = payload;
    }
  },
  actions: {
    [SuppliesAction.loadSupplies]: async ({ commit }, id: number) => {
      commit(
        SuppliesMutation.setSupplies,
        await new ApiClientFactory().suppliesClient().get(id)
      );
    },
    [SuppliesAction.clearSupplies]: ({ commit }) => {
      commit(SuppliesMutation.setSupplies, null);
    },
    [SuppliesAction.fetchSuppliesNames]: async ({ commit }) => {
      const suppliesNames = await new ApiClientFactory()
        .suppliesClient()
        .getSuppliesNames();
      commit(SuppliesMutation.setSuppliesNames, suppliesNames);
    },
    [SuppliesAction.fetchSuppliesList]: async ({ commit }) => {
      const suppliesList = await new ApiClientFactory()
        .suppliesClient()
        .getSuppliesList();
      commit(SuppliesMutation.setSuppliesList, suppliesList);
    }
  },
  getters: {
    [SuppliesGetter.supplies]: (state) => state[SuppliesState.supplies],
    [SuppliesGetter.suppliesNames]: (state) =>
      state[SuppliesState.suppliesNames]
  }
};
