import { OrderMaterialVewModel, OrderViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum OrderState {
  order = 'order',
  orderMaterial = 'orderMaterial',
  companyInvoiceInfo = 'companyInvoiceInfo'
}

export interface OrderStateTypes {
  [OrderState.order]: OrderViewModel | null;
  [OrderState.orderMaterial]: OrderMaterialVewModel | null;
  [OrderState.companyInvoiceInfo]: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  } | null;
}

export const enum OrderMutation {
  setOrder = 'setOrder',
  setOrderMaterial = 'setOrderMaterial',
  setCompanyInvoiceInfo = 'setCompanyInvoiceInfo',
  resetCompanyInvoiceInfo = 'resetCompanyInvoiceInfo'
}

export interface OrderMutationPayloadTypes {
  [OrderMutation.setOrder]: OrderViewModel | null;
  [OrderMutation.setOrderMaterial]: OrderMaterialVewModel | null;
  [OrderMutation.setCompanyInvoiceInfo]: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  } | null;
  [OrderMutation.resetCompanyInvoiceInfo]: null;
}

export const enum OrderAction {
  loadOrder = 'loadOrder',
  clearOrder = 'clearOrder',
  getOrderMaterial = 'getOrderMaterial'
}

export interface OrderActions {
  [OrderAction.loadOrder]: (id: number) => void;
  [OrderAction.clearOrder]: () => void;
  [OrderAction.getOrderMaterial]: (productId) => void;
}

export const enum OrderGetter {
  order = 'order',
  companyInvoiceInfo = 'companyInvoiceInfo'
}

export interface OrderGetterTypes {
  [OrderGetter.order]: OrderViewModel | null;
  [OrderGetter.companyInvoiceInfo]: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  } | null;
}

export const ORDER_STATE_NAMESPACE = 'OrderState';
export const OrderNamespace = namespace(ORDER_STATE_NAMESPACE);

export type OrderModule = Override<
  Module<OrderStateTypes, WebRootState>,
  TypedModule<
    OrderStateTypes,
    OrderMutationPayloadTypes,
    OrderActions,
    OrderGetterTypes,
    WebRootState
  >
>;
