import ApiClientFactory from '@/api/apiClientFactory';
import Vue from 'vue';

import {
  ExpensesAction,
  ExpensesGetter,
  ExpensesModule,
  ExpensesMutation,
  ExpensesState
} from './expenses.module-types';

export const expensesModule: ExpensesModule = {
  namespaced: true,
  state: {
    [ExpensesState.expenses]: null,
    [ExpensesState.companyInvoiceInfo]: {
      companyName: 'CÔNG TY IN ẤN QUẢNG CÁO PDCA',
      companyAddress: '119/45 Nguyễn Hồng Đào, P.14, Q.Tân Bình, TP.HCM ',
      companyPhone: '090 139 1815 - 091 346 1539.'
    }
  },
  mutations: {
    [ExpensesMutation.setExpenses]: (state, payload) => {
      state[ExpensesState.expenses] = payload;
    },
    [ExpensesMutation.setCompanyInvoiceInfo]: (state, payload) => {
      state[ExpensesState.companyInvoiceInfo] = {
        companyAddress: payload?.companyAddress ?? '',
        companyPhone: payload?.companyPhone ?? '',
        companyName: payload?.companyName ?? ''
      };
    },
    [ExpensesMutation.resetCompanyInvoiceInfo]: (state) => {
      state[ExpensesState.companyInvoiceInfo] = {
        companyName: 'CÔNG TY TNHH THẾ GIỚI STICKER',
        companyAddress: '119/45 Nguyễn Hồng Đào, P.14, Q.Tân Bình, TP.HCM',
        companyPhone: '090 139 1815 - 091 346 1539.'
      };
    }
  },
  actions: {
    [ExpensesAction.loadExpenses]: async ({ commit }, id: number) => {
      commit(
        ExpensesMutation.setExpenses,
        await new ApiClientFactory().expensesClient().get(id)
      );
    },
    [ExpensesAction.clearExpenses]: ({ commit }) => {
      commit(ExpensesMutation.setExpenses, null);
    }
    // [ExpensesAction.getExpensesMaterial]: async ({ commit }, productId) => {
    //   const clientApi = new ApiClientFactory();
    //   const result = await clientApi
    //     .expensesClient()
    //     .getExpensesMaterialByProductId(productId);

    //   commit(ExpensesMutation.setExpensesMaterial, result);
    // }
  },
  getters: {
    [ExpensesGetter.expenses]: (state) => state[ExpensesState.expenses],
    [ExpensesGetter.companyInvoiceInfo]: (state) =>
      state[ExpensesState.companyInvoiceInfo]
  }
};
