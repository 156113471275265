import { Component, Vue } from 'vue-property-decorator';
import { BBreadcrumb, BBreadcrumbItem, BCardText } from 'bootstrap-vue';
import BCardCode from '@/@core/components/b-card-code';
import {
  BreadcrumbGetter,
  BreadcrumbItem,
  BreadcrumbNamespace
} from '@/store/breadcrumb/breadcrumb.module-types';

@Component({
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCardText,
    BCardCode
  }
})
export default class Breadcrumb extends Vue {
  @BreadcrumbNamespace.Getter(BreadcrumbGetter.items)
  items!: BreadcrumbItem[];
}
