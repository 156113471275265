import ApiClientFactory from '@/api/apiClientFactory';

import {
  CataloguePriceAction,
  CataloguePriceGetter,
  CataloguePriceModule,
  CataloguePriceMutation,
  CataloguePriceState
} from './catalogue-price.module-types';

export const cataloguePriceModule: CataloguePriceModule = {
  namespaced: true,
  state: {
    [CataloguePriceState.catalougePriceList]: null
  },
  mutations: {
    [CataloguePriceMutation.setCataloguePriceList]: (state, payload) => {
      state[CataloguePriceState.catalougePriceList] = payload;
    }
  },
  actions: {
    [CataloguePriceAction.loadCataloguePriceList]: async ({ commit }) => {
      commit(
        CataloguePriceMutation.setCataloguePriceList,
        await new ApiClientFactory().priceClient().getCataloguePrice()
      );
    }
  },
  getters: {
    [CataloguePriceGetter.catalougePriceList]: (state) =>
      state[CataloguePriceState.catalougePriceList]
  }
};
