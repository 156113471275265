import mainFunctions from '../main-functions';
import others from '../others';
export default [
  ...mainFunctions,
  {
    header: 'OtherFunctions',
    icon: 'MoreHorizontalIcon',
    children: [...others]
  }
];
