import { SampleViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum SampleState {
  sample = 'sample'
}

export interface SampleStateTypes {
  [SampleState.sample]: SampleViewModel | null;
}

export const enum SampleMutation {
  setSample = 'setSample'
}

export interface SampleMutationPayloadTypes {
  [SampleMutation.setSample]: SampleViewModel | null;
}

export const enum SampleAction {
  loadSample = 'loadSample',
  clearSample = 'clearSample'
}

export interface SampleActions {
  [SampleAction.loadSample]: (id: number) => void;
  [SampleAction.clearSample]: () => void;
}

export const enum SampleGetter {
  sample = 'sample'
}

export interface SampleGetterTypes {
  [SampleGetter.sample]: SampleViewModel | null;
}

export const SAMPLE_STATE_NAMESPACE = 'SampleState';
export const sampleNamespace = namespace(SAMPLE_STATE_NAMESPACE);

export type SampleModule = Override<
  Module<SampleStateTypes, WebRootState>,
  TypedModule<
    SampleStateTypes,
    SampleMutationPayloadTypes,
    SampleActions,
    SampleGetterTypes,
    WebRootState
  >
>;
