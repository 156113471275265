import ApiClientFactory from '@/api/apiClientFactory';
import { getListModule } from '@/utility/pagination/get-list-module';
import { getListDispatcher } from '@/utility/pagination/get-list.dispatcher';
import {
  getItemsPerPage,
  getSortCriteria,
  getValueOrUndefined,
  getZeroBasedPageNumber,
  Pagination
} from '@/utility/pagination/pagination';
import { namespace } from 'vuex-class';

export const OTHER_SERVICE_LIST_STATE_NAMESPACE = 'otherServiceListState';

export const otherServiceListNamespace = namespace(
  OTHER_SERVICE_LIST_STATE_NAMESPACE
);

export type OtherServiceListInput = Pagination;

const getOtherServiceList = (input: OtherServiceListInput) =>
  new ApiClientFactory()
    .otherServiceClient()
    .getList(
      getZeroBasedPageNumber(input),
      getItemsPerPage(input),
      getValueOrUndefined(input.searchText),
      getSortCriteria(input)
    );

export const otherServiceListDispatcher = getListDispatcher<OtherServiceListInput>(
  OTHER_SERVICE_LIST_STATE_NAMESPACE
);
export const otherServiceListModule = getListModule(getOtherServiceList);
