import { PriceListViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum PriceState {
  priceList = 'priceList'
}

export interface PriceStateTypes {
  [PriceState.priceList]: PriceListViewModel[] | null;
}

export const enum PriceMutation {
  setPriceList = 'setPriceList'
}

export interface PriceMutationPayloadTypes {
  [PriceMutation.setPriceList]: PriceListViewModel[] | null;
}

export const enum PriceAction {
  loadPriceList = 'loadPriceList'
}

export interface PriceActions {
  [PriceAction.loadPriceList]: () => void;
}

export const enum PriceGetter {
  priceList = 'priceList'
}

export interface PriceGetterTypes {
  [PriceGetter.priceList]: PriceListViewModel[] | null;
}

export const PRICE_STATE_NAMESPACE = 'PriceState';
export const PriceNamespace = namespace(PRICE_STATE_NAMESPACE);

export type PriceModule = Override<
  Module<PriceStateTypes, WebRootState>,
  TypedModule<
    PriceStateTypes,
    PriceMutationPayloadTypes,
    PriceActions,
    PriceGetterTypes,
    WebRootState
  >
>;
