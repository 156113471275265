import ApiClientFactory from '@/api/apiClientFactory';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { getListModule } from '@/utility/pagination/get-list-module';
import { getListDispatcher } from '@/utility/pagination/get-list.dispatcher';
import {
  getItemsPerPage,
  getSortCriteria,
  getValueOrUndefined,
  getZeroBasedPageNumber,
  Pagination
} from '@/utility/pagination/pagination';
import { namespace } from 'vuex-class';

export const EMPLOYEE_LIST_STATE_NAMESPACE = 'employeeListState';

export const employeeListNamespace = namespace(EMPLOYEE_LIST_STATE_NAMESPACE);

export interface EmployeeListInput extends Pagination {}

const getEmployeeList = (input: EmployeeListInput) => {
  return new ApiClientFactory()
    .employeeClient()
    .getListPaged(
      getZeroBasedPageNumber(input),
      getItemsPerPage(input),
      getValueOrUndefined(input.searchText),
      getSortCriteria(input)
    );
};

export const employeeListDispatcher = getListDispatcher<EmployeeListInput>(
  EMPLOYEE_LIST_STATE_NAMESPACE
);
export const employeeListModule = getListModule(getEmployeeList);
