import { SliderViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum SliderState {
  slider = 'slider'
}

export interface SliderStateTypes {
  [SliderState.slider]: SliderViewModel | null;
}

export const enum SliderMutation {
  setSlider = 'setSlider'
}

export interface SliderMutationPayloadTypes {
  [SliderMutation.setSlider]: SliderViewModel | null;
}

export const enum SliderAction {
  loadSlider = 'loadSlider',
  clearSlider = 'clearSlider'
}

export interface SliderActions {
  [SliderAction.loadSlider]: () => void;
  [SliderAction.clearSlider]: () => void;
}

export const enum SliderGetter {
  slider = 'slider'
}

export interface SliderGetterTypes {
  [SliderGetter.slider]: SliderViewModel | null;
}

export const SLIDER_STATE_NAMESPACE = 'SliderState';
export const SliderNamespace = namespace(SLIDER_STATE_NAMESPACE);

export type SliderModule = Override<
  Module<SliderStateTypes, WebRootState>,
  TypedModule<
    SliderStateTypes,
    SliderMutationPayloadTypes,
    SliderActions,
    SliderGetterTypes,
    WebRootState
  >
>;
