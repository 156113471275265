import ApiClientFactory from '@/api/apiClientFactory';
import {
  VendorAction,
  VendorGetter,
  VendorModule,
  VendorMutation,
  VendorState
} from './vendor.module-types';

export const vendorModule: VendorModule = {
  namespaced: true,
  state: {
    [VendorState.vendors]: []
  },
  mutations: {
    [VendorMutation.setVendors]: (state, payload) => {
      state[VendorState.vendors] = payload;
    }
  },
  actions: {
    [VendorAction.fetchVendors]: async ({ commit }) => {
      const clientApi = new ApiClientFactory();
      const result = await clientApi.vendorClient().getList();

      commit(VendorMutation.setVendors, result);
    }
  },
  getters: {
    [VendorGetter.vendors]: (state) => state[VendorState.vendors]
  }
};
