import ApiClientFactory from '@/api/apiClientFactory';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { getListModule } from '@/utility/pagination/get-list-module';
import { getListDispatcher } from '@/utility/pagination/get-list.dispatcher';
import {
  getItemsPerPage,
  getSortCriteria,
  getValueOrUndefined,
  getZeroBasedPageNumber,
  Pagination
} from '@/utility/pagination/pagination';
import { namespace } from 'vuex-class';

export const PRODUCING_LIST_STATE_NAMESPACE = 'producingListState';

export const producingListNamespace = namespace(PRODUCING_LIST_STATE_NAMESPACE);

export interface ProducingListInput extends Pagination {
  filterStatus: DropdownOption[];
  dateFrom?: Date;
  dateTo?: Date;
  filterEmployee?: string;
  isOrderForDesign: boolean;
  isOrderForPrinting: boolean;
}

const getProducingList = (input: ProducingListInput) => {
  let from, to;
  if (input.dateFrom) {
    from = new Date(
      input.dateFrom.getFullYear(),
      input.dateFrom.getMonth(),
      input.dateFrom.getDate(),
      0,
      0,
      0
    );
  }

  if (input.dateTo) {
    // input.dateTo.setDate(input.dateTo.getDate() + 1);
    to = new Date(
      input.dateTo.getFullYear(),
      input.dateTo.getMonth(),
      input.dateTo.getDate(),
      0,
      0,
      0
    );
    to.setDate(to.getDate() + 1);
  }

  return new ApiClientFactory()
    .orderClient()
    .getProducingList(
      to ? to.getTime() : null,
      from ? from.getTime() : null,
      getValueOrUndefined(input.filterStatus.map((x) => x.value)),
      getValueOrUndefined(input.filterEmployee),
      input.isOrderForDesign,
      input.isOrderForPrinting,
      getZeroBasedPageNumber(input),
      getItemsPerPage(input),
      getValueOrUndefined(input.searchText),
      getSortCriteria(input)
    );
};

export const producingListDispatcher = getListDispatcher<ProducingListInput>(
  PRODUCING_LIST_STATE_NAMESPACE
);
export const producingListModule = getListModule(getProducingList);
