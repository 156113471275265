import { CustomerViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum CustomerState {
  customer = 'customer',
  customers = 'customers'
}

export interface CustomerStateTypes {
  [CustomerState.customer]: CustomerViewModel | null;
  [CustomerState.customers]: CustomerViewModel[] | null;
}

export const enum CustomerMutation {
  setCustomer = 'setCustomer',
  setCustomers = 'setCustomers'
}

export interface CustomerMutationPayloadTypes {
  [CustomerMutation.setCustomer]: CustomerViewModel | null;
  [CustomerMutation.setCustomers]: CustomerViewModel[] | null;
}

export const enum CustomerAction {
  //loadCustomer = 'loadCustomer',
  clearCustomer = 'clearCustomer',
  fetchCustomers = 'fetchCustomers'
}

export interface CustomerActions {
  //[CustomerAction.loadCustomer]: (id: string) => void;
  [CustomerAction.clearCustomer]: () => void;
  [CustomerAction.fetchCustomers]: () => Promise<void>;
}

export const enum CustomerGetter {
  customer = 'customer',
  customers = 'customers'
}

export interface CustomerGetterTypes {
  [CustomerGetter.customer]: CustomerViewModel | null;
  [CustomerGetter.customers]: CustomerViewModel[] | null;
}

export const CUSTOMER_STATE_NAMESPACE = 'CustomerState';
export const CustomerNamespace = namespace(CUSTOMER_STATE_NAMESPACE);

export type CustomerModule = Override<
  Module<CustomerStateTypes, WebRootState>,
  TypedModule<
    CustomerStateTypes,
    CustomerMutationPayloadTypes,
    CustomerActions,
    CustomerGetterTypes,
    WebRootState
  >
>;
