import ApiClientFactory from '@/api/apiClientFactory';
import {
  EmployeeAction,
  EmployeeGetter,
  EmployeeModule,
  EmployeeMutation,
  EmployeeState
} from './employee.module-types';

export const employeeModule: EmployeeModule = {
  namespaced: true,
  state: {
    [EmployeeState.employee]: null,
    [EmployeeState.employees]: [],
    [EmployeeState.saleEmployees]: []
  },
  mutations: {
    [EmployeeMutation.setEmployee]: (state, payload) => {
      state[EmployeeState.employee] = payload;
    },
    [EmployeeMutation.setEmployees]: (state, payload) => {
      state[EmployeeState.employees] = payload;
    },
    [EmployeeMutation.setSaleEmployees]: (state, payload) => {
      state[EmployeeState.saleEmployees] = payload;
    }
  },
  actions: {
    [EmployeeAction.loadEmployee]: async ({ commit }, id: string) => {
      commit(
        EmployeeMutation.setEmployee,
        await new ApiClientFactory().employeeClient().get(id)
      );
    },
    [EmployeeAction.clearEmployee]: ({ commit }) => {
      commit(EmployeeMutation.setEmployee, null);
    },
    [EmployeeAction.fetchEmployees]: async ({ commit }) => {
      const clientApi = new ApiClientFactory();
      const result = await clientApi.employeeClient().getList();

      commit(EmployeeMutation.setEmployees, result);
    },
    [EmployeeAction.fetchSaleEmployees]: async ({ commit }) => {
      const clientApi = new ApiClientFactory();
      const result = await clientApi.employeeClient().getSaleEmployees();

      commit(EmployeeMutation.setSaleEmployees, result);
    }
  },
  getters: {
    [EmployeeGetter.employee]: (state) => state[EmployeeState.employee]
  }
};
