import ApiClientFactory from '@/api/apiClientFactory';
import { getListModule } from '@/utility/pagination/get-list-module';
import { getListDispatcher } from '@/utility/pagination/get-list.dispatcher';
import {
  getItemsPerPage,
  getSortCriteria,
  getValueOrUndefined,
  getZeroBasedPageNumber,
  Pagination
} from '@/utility/pagination/pagination';
import { namespace } from 'vuex-class';

export const SUPPLIES_CATEGORY_LIST_STATE_NAMESPACE =
  'SuppliesCategoryListState';

export const suppliesCategoryListNamespace = namespace(
  SUPPLIES_CATEGORY_LIST_STATE_NAMESPACE
);

export type SuppliesCategoryListInput = Pagination;

const getSuppliesCategoryList = (input: SuppliesCategoryListInput) =>
  new ApiClientFactory()
    .suppliesCategoryClient()
    .getListPaged(
      getZeroBasedPageNumber(input),
      getItemsPerPage(input),
      getValueOrUndefined(input.searchText),
      getSortCriteria(input)
    );

export const suppliesCategoryListDispatcher = getListDispatcher<SuppliesCategoryListInput>(
  SUPPLIES_CATEGORY_LIST_STATE_NAMESPACE
);
export const suppliesCategoryListModule = getListModule(
  getSuppliesCategoryList
);
