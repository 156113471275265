import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

export function registerVeeValidate() {
  Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
  });

  // with typescript
  for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, {
      ...validation
    });
  }

  extend('required', {
    ...rules.required,
    message: 'Vui lòng nhập giá trị'
  });

  extend('not0', {
    validate: (value) => {
      return value > 0;
    },
    message: 'Vui lòng nhập giá trị hợp lệ'
  });

  extend('email', {
    ...rules.email,
    message: 'Vui lòng nhập giá trị hợp lệ'
  });

  extend('numeric', {
    ...rules.numeric,
    message: 'Vui lòng nhập giá trị hợp lệ'
  });

  extend('maxQuantity', {
    validate: (value) => {
      return value <= 10000000;
    },
    message: 'Vui lòng nhập giá trị hợp lệ'
  });
}
