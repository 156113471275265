import { SuppliesCategoryViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum SuppliesCategoryState {
  suppliesCategory = 'suppliesCategory',
  suppliesCategories = 'suppliesCategories'
}

export interface SuppliesCategoryStateTypes {
  [SuppliesCategoryState.suppliesCategory]: SuppliesCategoryViewModel | null;
  [SuppliesCategoryState.suppliesCategories]:
    | SuppliesCategoryViewModel[]
    | null;
}

export const enum SuppliesCategoryMutation {
  setSuppliesCategory = 'setSuppliesCategory',
  setSuppliesCategories = 'setSuppliesCategories'
}

export interface SuppliesCategoryMutationPayloadTypes {
  [SuppliesCategoryMutation.setSuppliesCategory]: SuppliesCategoryViewModel | null;
  [SuppliesCategoryMutation.setSuppliesCategories]:
    | SuppliesCategoryViewModel[]
    | null;
}

export const enum SuppliesCategoryAction {
  loadSuppliesCategory = 'loadSuppliesCategory',
  clearSuppliesCategory = 'clearSuppliesCategory',
  fetchSuppliesCategories = 'fetchSuppliesCategories'
}

export interface SuppliesCategoryActions {
  [SuppliesCategoryAction.loadSuppliesCategory]: (id: number) => void;
  [SuppliesCategoryAction.clearSuppliesCategory]: () => void;
  [SuppliesCategoryAction.fetchSuppliesCategories]: () => void;
}

export const enum SuppliesCategoryGetter {
  suppliesCategory = 'suppliesCategory',
  suppliesCategories = 'suppliesCategories'
}

export interface SuppliesCategoryGetterTypes {
  [SuppliesCategoryGetter.suppliesCategory]: SuppliesCategoryViewModel | null;
  [SuppliesCategoryGetter.suppliesCategories]:
    | SuppliesCategoryViewModel[]
    | null;
}

export const SUPPLIES_CATEGORY_STATE_NAMESPACE = 'SuppliesCategoryState';
export const SuppliesCategoryNamespace = namespace(
  SUPPLIES_CATEGORY_STATE_NAMESPACE
);

export type SuppliesCategoryModule = Override<
  Module<SuppliesCategoryStateTypes, WebRootState>,
  TypedModule<
    SuppliesCategoryStateTypes,
    SuppliesCategoryMutationPayloadTypes,
    SuppliesCategoryActions,
    SuppliesCategoryGetterTypes,
    WebRootState
  >
>;
