import ApiClientFactory from '@/api/apiClientFactory';
import {
  ProductCategoryAction,
  ProductCategoryGetter,
  ProductCategoryModule,
  ProductCategoryMutation,
  ProductCategoryState
} from './productCategory.module-types';

export const productCategoryModule: ProductCategoryModule = {
  namespaced: true,
  state: {
    [ProductCategoryState.productCategory]: null,
    [ProductCategoryState.productCategories]: []
  },
  mutations: {
    [ProductCategoryMutation.setProductCategory]: (state, payload) => {
      state[ProductCategoryState.productCategory] = payload;
    },
    [ProductCategoryMutation.setProductCategories]: (state, payload) => {
      state[ProductCategoryState.productCategories] = payload;
    }
  },
  actions: {
    [ProductCategoryAction.loadProductCategory]: async (
      { commit },
      id: number
    ) => {
      commit(
        ProductCategoryMutation.setProductCategory,
        await new ApiClientFactory().productCategoryClient().get(id)
      );
    },
    [ProductCategoryAction.clearProductCategory]: ({ commit }) => {
      commit(ProductCategoryMutation.setProductCategory, null);
    },
    [ProductCategoryAction.fetchProductCategories]: async ({ commit }) => {
      let productCategories = await new ApiClientFactory()
        .productCategoryClient()
        .getList();
      productCategories = productCategories.sort(
        (a, b) => a.orderNumber - b.orderNumber
      );
      commit(ProductCategoryMutation.setProductCategories, productCategories);
    }
  },
  getters: {
    [ProductCategoryGetter.productCategory]: (state) =>
      state[ProductCategoryState.productCategory]
  }
};
