import ApiClientFactory from '@/api/apiClientFactory';
import { getListModule } from '@/utility/pagination/get-list-module';
import { getListDispatcher } from '@/utility/pagination/get-list.dispatcher';
import {
  getItemsPerPage,
  getSortCriteria,
  getValueOrUndefined,
  getZeroBasedPageNumber,
  Pagination
} from '@/utility/pagination/pagination';
import { namespace } from 'vuex-class';

export const SAMPLE_LIST_STATE_NAMESPACE = 'sampleListState';

export const sampleListNamespace = namespace(SAMPLE_LIST_STATE_NAMESPACE);

export type SampleListInput = Pagination;

const getSampleList = (input: SampleListInput) =>
  new ApiClientFactory()
    .sampleClient()
    .getList(
      0,
      0,
      0,
      null,
      getZeroBasedPageNumber(input),
      getItemsPerPage(input),
      getValueOrUndefined(input.searchText),
      getSortCriteria(input)
    );

export const sampleListDispatcher = getListDispatcher<SampleListInput>(
  SAMPLE_LIST_STATE_NAMESPACE
);
export const sampleListModule = getListModule(getSampleList);
