import Vue from 'vue';
import {
  ToastPlugin,
  ModalPlugin,
  SidebarPlugin,
  BootstrapVueIcons
} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import VueQuillEditor from 'vue-quill-editor';
import * as Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);
import i18n from '@/libs/i18n';
import router from './router';
import store from '@/store';
import App from './App.vue';
import * as validation from './utility/validation-register';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueNumberFormat from '@coders-tm/vue-number-format';

const ImageFormatAttributesList = [
  'float',
  'display',
  'margin',
  'width',
  'style'
];

const BaseImageFormat = Quill.import('formats/image');
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        (this as any).domNode.setAttribute(name, value);
      } else {
        (this as any).domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
Quill.register(ImageFormat, true);
const Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '14px', '16px', '18px', '20px'];
Quill.register(Size, true);
// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/portal-vue';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import '@/libs/tour';

// Axios Mock Adapter
import '@/@fake-db/db';
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueQuillEditor, {
  modules: {
    imageResize: true,
    toolbar: [
      [{ size: Size.whitelist }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'super' }, { script: 'sub' }],
      [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['direction', { align: [] }],
      ['link', 'image', 'video', 'formula'],
      ['clean']
    ]
  }
});
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(SidebarPlugin);

// Composition API
Vue.use(VueCompositionAPI);
Vue.use(VueSweetalert2);
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

Vue.use(VueNumberFormat, { precision: 2 });

validation.registerVeeValidate();
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(BootstrapVueIcons);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
