import mainFunctions from '../main-functions';
import others from '../others';
// Array of sections
export default [
  {
    header: 'MainFunctions'
  },
  ...mainFunctions,
  {
    title: 'OtherFunctions',
    icon: 'MoreHorizontalIcon',
    children: [...others]
  }
];
