import ApiClientFactory from '@/api/apiClientFactory';

import {
  SliderAction,
  SliderGetter,
  SliderModule,
  SliderMutation,
  SliderState
} from './slider.module-types';

export const sliderModule: SliderModule = {
  namespaced: true,
  state: {
    [SliderState.slider]: null
  },
  mutations: {
    [SliderMutation.setSlider]: (state, payload) => {
      state[SliderState.slider] = payload;
    }
  },
  actions: {
    [SliderAction.loadSlider]: async ({ commit }) => {
      commit(
        SliderMutation.setSlider,
        await new ApiClientFactory().sliderClient().getList()
      );
    },
    [SliderAction.clearSlider]: ({ commit }) => {
      commit(SliderMutation.setSlider, null);
    }
  },
  getters: {
    [SliderGetter.slider]: (state) => state[SliderState.slider]
  }
};
