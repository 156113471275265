import { CataloguePriceListViewModel } from '@/api/api';
import { Module } from 'vuex';
import { TypedModule } from '../utility/module-utility-types';
import { WebRootState } from '../web-root-state';
import { namespace } from 'vuex-class';

export const enum CataloguePriceState {
  catalougePriceList = 'catalougePriceList'
}

export interface CataloguePriceStateTypes {
  [CataloguePriceState.catalougePriceList]:
    | CataloguePriceListViewModel[]
    | null;
}

export const enum CataloguePriceMutation {
  setCataloguePriceList = 'setCataloguePriceList'
}

export interface CataloguePriceMutationPayloadTypes {
  [CataloguePriceMutation.setCataloguePriceList]:
    | CataloguePriceListViewModel[]
    | null;
}

export const enum CataloguePriceAction {
  loadCataloguePriceList = 'loadCataloguePriceList'
}

export interface CataloguePriceActions {
  [CataloguePriceAction.loadCataloguePriceList]: () => void;
}

export const enum CataloguePriceGetter {
  catalougePriceList = 'catalougePriceList'
}

export interface CataloguePriceGetterTypes {
  [CataloguePriceGetter.catalougePriceList]:
    | CataloguePriceListViewModel[]
    | null;
}

export const CATALOGUE_PRICE_STATE_NAMESPACE = 'CataloguePriceState';
export const CataloguePriceNamespace = namespace(
  CATALOGUE_PRICE_STATE_NAMESPACE
);

export type CataloguePriceModule = Override<
  Module<CataloguePriceStateTypes, WebRootState>,
  TypedModule<
    CataloguePriceStateTypes,
    CataloguePriceMutationPayloadTypes,
    CataloguePriceActions,
    CataloguePriceGetterTypes,
    WebRootState
  >
>;
